import "bootstrap/dist/css/bootstrap.min.css";

import * as Vue from 'vue';
import App from './App.vue';

import store from './store';
//import axios from 'axios';

//Vue.prototype.$http = axios;

Vue.createApp(App)
    .use(store)
    //.provide('$axios', axios)
    .mount('#app');

//Vue.config.globalProperties.$axios = axios;


import "bootstrap/dist/js/bootstrap.js";
