<template>
   <div class="mask d-flex align-items-center h-100 gradient-custom-3">
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-9 col-lg-7 col-xl-6">
          <div class="card" style="border-radius: 15px;">
            <div class="card-body p-5">
              <h2 class="text-uppercase text-center mb-5">Subscribe for Newsletter</h2>

              <form >

                <div data-mdb-input-init class="form-outline mb-4">
                    <label for="registerName">Name:</label>
                    <input v-model.trim="registerName" placeholder="Name..." />
                </div>

                <div data-mdb-input-init class="form-outline mb-4">
                    <label for="registerEmail">Email:</label>
                    <input v-model.trim="registerEmail" placeholder="Email..." />
                </div>

                <div class="d-flex justify-content-center">
                  <button  type="button" data-mdb-button-init @click="registerAccount"
                    data-mdb-ripple-init class="btn btn-success btn-block btn-lg gradient-custom-4 text-body">Register Not Working Yet</button>
                </div>


                <div class="d-flex justify-content-center">
                    <!-- <span v-if="!user?.email"> -->
                        
                        <p class="fs-6" ><br/><br/> 
                            Processing through api ... <br/>
                            Name:   {{ user?.fullname }} <br/>
                            Email:   {{ user?.email }}  <br/>
                            Status:   {{ user?.status }} 
                        </p>
                    <!-- </span> -->
                </div>
                
                
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  
  data() {
    return {


    }
  },
  computed: {
    user() {
        return this.$store.getters.getUser;
    }
  },
  methods: {
    registerAccount: function() {

        const user = {
            fullname: this.registerName,
            email: this.registerEmail,
            status: 'Sending to server.',
        };
        //this.$store.commit('setUser', user);
        //this.$router.push('/');
        this.$store.dispatch('registerUser', user);
    }
  },
  created() {
    //this.$store.dispatch('checkApi');
    //this.$store.state.user;
  },
  beforeMount() {
    this.$store.dispatch('checkApi');
  },
  mounted() {
    //this.$store.dispatch('checkApi');
    //this.$store.state.user;
  }

}
</script>