import axios from "axios";

export default axios.create({
  baseURL: "https://www.tychenet.com/api/test/0.1/",
  //baseURL: "http://190.92.179.45/api/test/0.1/",
  // baseURL: "http://localhost:8080/api/test/0.1/",
  headers: {
    "Content-type": "application/json",
    "Common": "Access-Control-Allow-Origin"
  }
});