<template>
  <div class="hello">
    <!-- <h4>{{ msg }}</h4> -->
    <p class="fs-4" > REST test :  [ {{ availableInfo }} ] </p>
  </div>
  <div class="opacity-25">
    <br/><br/>
    <div class="spinner-grow spinner-grow-sm text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-warning" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-info" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-light" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <div class="spinner-grow spinner-grow-sm text-dark" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>&nbsp;
    <br/> <br/>
  </div>
</template>

<script>


export default {
  
  data() {
    return {
      name: 'Home',
      msg: 'Testing backend ...'

    }
  },
  computed: {
    availableInfo() {
      return this.$store.state.info;
    }
  },
  methods: {
    registerInfo() {
      const s = 'xxx';
      this.$store.commit('setInfo', { s });
    }
  },
  created() {
    //this.$store.dispatch('checkApi');
  },
  beforeMount() {
    this.$store.dispatch('checkApi');
  },
  mounted() {
    //this.$store.dispatch('checkApi');
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
